jQuery(document).ready(function($) {
    
   $('.reviews__slider').slick({
        arrows: true,
        dots: true,
        prevArrow: $('.reviews__buttons-prev'),
        nextArrow: $('.reviews__buttons-next'),
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
         {
           breakpoint: 993,
           settings: {
             slidesToShow: 2
           }
         },
         {
           breakpoint: 577,
           settings: {
             slidesToShow: 1
           }
         }
       ]
   });  

   document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
          e.preventDefault();
  
          document.querySelector(this.getAttribute('href')).scrollIntoView({
              behavior: 'smooth'
          });
      });
  });

  $(document).scroll(function() {
    var scrollTop     = $(window).scrollTop(),
      elementOffset = $('.header__order').offset().top,
      distance      = (elementOffset - scrollTop);
    if(distance < 250) {
      $('.heart-block').addClass('active');
      setTimeout(function() {
        $('.first-circle').addClass('pulse');
      }, 4000);
      setTimeout(function() {
        $('.second-circle').addClass('pulse');
      }, 4250);
    }
 });

});

